import React from 'react';
import howImg1 from '../../assets/images/how-1.jpg';
import howImg2 from '../../assets/images/how-2.jpg';
import howImg3 from '../../assets/images/how-3.jpg';
import howImg4 from '../../assets/images/how-4.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const How = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className='how'>
            <div className='how__container'>
                <div className='how__header'>
                    <h1 className='font-52'> {t("How.h1",`Create an audio guide and we give you support for 13 languages`)} </h1>
                </div>
                <div className='how__row'>
                    <div className='how__col'>
                        <h4 className='font-25'> {t("How.h4_How_does_it_work?", ` How does it work?`)}</h4>
                        <p className='font-15'>{t("How.p_1", `We have the technology to make it easy for you to create an audio guide and we will support you within a week to make the audio guide available in 13 languages (English, Spanish, German, Dutch, Chinese, French, Japanese, Italian, Portuguese, Russian, Danish, Finnish, Romanian).`)} </p>
                        <div className='startbtn'>
                            <Link to={'/login'}>
                                <button className="btn-primary mb-3">
                                {t("How.btn_login", `Let's start`)} 
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='how__col'>
                        <img className='how__img' src={howImg1} alt="img how" />
                    </div>
                </div>
                <div className='how__content'>
                    <h4 className='font-25'> {t("How.h4_In_3_steps", `In 3 steps`)} </h4>
                    <p className='font-15'>{t("How.p_2", `Tour creator is only for museums that want to create and share their audioguides, thus creating a <br /> simple content to reach more of their customers and with only 3 simple steps it is possible to do it.`)} </p>
                    <div className="how__steps">
                        <div className="how__step">
                            <div className="how__number">1</div>
                            <p className='font-15'> {t("How.p_3",`Create an outdoor tour or indoors by adding and combining audio texts and images!`)} </p>
                            <div className='howimgs'>

                                <img className='how__img' src={howImg2} alt="img how" />
                            </div>
                        </div>
                        <div className="how__step">
                            <div className="how__number">2</div>
                            <p className='font-15'> {t("How.p_4",`When you create the tour, you can publish it and it will be instantly available on the mobile <br /> platform, if you think you have made a mistake, you can always go back to the platform and correct it.`)} </p>
                            <div className='howimgs'>

                                <img className='how__img' src={howImg3} alt="img how" />
                            </div>
                        </div>
                        <div className="how__step">
                            <div className="how__number">3</div>
                            <p className='font-15'> {t("How.p_5",`Your tour is already published, our team will work to make your tour available in 13 languages, so you can attract more people and promote it.`)}</p>
                            <div className='howimgs'>

                                <img className='how__img' src={howImg4} alt="img how" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default How;
