import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ActiveTabContext } from "../layout/dashboardLayout";

const SetActiveTab = (activeMainMenuTab = "", activeSubMenuTab = "") => {
  const location = useLocation();
  const [activeTabs, setActiveTabs] = useContext(ActiveTabContext);
  useEffect(() => {
    const newActiveTabs = { ...activeTabs };
    newActiveTabs.mainMenu = activeMainMenuTab;
    newActiveTabs.subMenu = activeSubMenuTab;
    setActiveTabs(newActiveTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setActiveTabs]);
};

export default SetActiveTab;
