import React, { createContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Navbar from "./Navbar";
import burgerIcon from "../../assets/images/burgerIcon.svg";
import userIcon from "../../assets/images/user-icon-2.png";
import downArrowImg from "../../assets/images/down-arrow-2.png";
// import { apiSlice } from '../../features/api/apiSlice';
import { useGetUserInfoQuery } from "../../features/auth/authSlice";

import { useTranslation } from "react-i18next";
export const OutletHeightContext = createContext();
export const SidebarMenusContext = createContext();
export const ActiveTabContext = createContext();

const DashboardLayout = () => {
  const [isVhNeeded, setIsVhNeeded] = useState();
  const [sidebarMenus, setSidebarMenus] = useState([]);
  const [activeTabs, setActiveTabs] = useState({ mainMenu: "", subMenu: "" });
  const [showSidebar, setShowSidebar] = useState(true);
  const dashboardLayoutTopRef = useRef();
  const [accountType, setAccountType] = useState("");
  const [showSwitchingAccountDropdown, setShowSwitchingAccountDropdown] =
    useState(false);
  const { t } = useTranslation();
  /** -----RTK----- */
  const { data: loggedInUser } = useGetUserInfoQuery(1);

  /**------React Router Hooks------ */
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [showSubMenu, setShowSubMenu] = useState(false);

  /** -----Event Handlers---------- */
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  /**----useEffects---- */
  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];
    html && html.classList.add("overflow-hidden");

    return () => html.classList.remove("overflow-hidden");
  }, []);

  useEffect(() => {
    if (dashboardLayoutTopRef?.current !== "") {
      dashboardLayoutTopRef.current.scrollTop = 0;
    }
    window.scrollTo({ top: 0, behavior: "smooth" });

    /** --Checking which dashboard is in use--- */
    if (
      pathname.includes("/admin/") ||
      searchParams.get("editor") === "admin"
    ) {
      setAccountType("administrator");
    } else {
      setAccountType("creator");
    }
  }, [pathname]);

  const handleAccountChange = (e) => {
    if (e.target.value === "administrator") {
      navigate("/admin/dashboard");
    }
    if (e.target.value === "creator") {
      navigate("/dashboard");
    }
    setShowSwitchingAccountDropdown(false);
  };
  let SearchQuery = window.location.search.substring(1);

  return (
    <div className="relative bg-gray-100">
      <div className="h-[75px] bg-theme-secondary"></div>
      <div className="max-w-[1440px] mx-auto overflow-hidden absolute top-0 left-0 right-0">
        <div className="grid grid-cols-11">
          <aside
            className={`${
              showSidebar ? "block" : "hidden"
            } col-span-3 h-screen overflow-auto border-r bg-white border-gray-500`}
          >
            <div className="sticky top-0">
              <div className="bg-theme-secondary">
                <div className="h-[75px] flex justify-between items-center text-white px-9 custom-container-393-XXX">
                  <Link to="/dashboard">
                    <p className="theme-text-24">World Tour Audio Guide</p>
                  </Link>
                  <img
                    onClick={toggleSidebar}
                    className="w-[25px] cursor-pointer"
                    src={burgerIcon}
                    alt=""
                  />
                </div>
              </div>
              <div className="border-b border-gray-500 bg-white">
                <div className="grid grid-cols-12 gap-3 justify-center items-center bg-white py-7 px-9 custom-container-393-XXX">
                  <div className="col-span-3 flex justify-end">
                    <img
                      className="w-[45px] 2xl:w-[85%]"
                      src={userIcon}
                      alt=""
                    />
                  </div>
                  <div className="col-span-6">
                    <div className="pl-3">
                      <p className="theme-text-28 mb-[4px] font-semibold line-clamp-2 break-all">
                        {loggedInUser?.data?.user_name}
                      </p>
                      <p className="theme-text-24">
                        {accountType === "administrator" &&
                          t("common.admin", "Administrator")}
                        {accountType === "creator" &&
                          t("common.creator", "Creator")}
                        {/* {accountType.charAt(0).toUpperCase() +
                          accountType.slice(1)} */}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-3 flex justify-start">
                    {loggedInUser?.data?.is_admin === 1 && (
                      <img
                        onClick={() =>
                          setShowSwitchingAccountDropdown(
                            !showSwitchingAccountDropdown
                          )
                        }
                        className={`w-[30px] cursor-pointer ${
                          showSwitchingAccountDropdown &&
                          "origin-center rotate-180"
                        }`}
                        src={downArrowImg}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                {showSwitchingAccountDropdown &&
                  loggedInUser?.data?.is_admin === 1 && (
                    <div
                      className={`grid gap-3 border-t border-gray-500 py-5 px-20`}
                    >
                      <div className="flex gap-3 items-center">
                        <input
                          onChange={handleAccountChange}
                          defaultChecked={
                            accountType === "administrator" && true
                          }
                          className="h-5 w-5 cursor-pointer"
                          type="radio"
                          name="account_type"
                          value="administrator"
                          id="administrator"
                        />
                        <label
                          htmlFor="administrator"
                          className="theme-text-24 cursor-pointer"
                        >
                          {t("common.admin", "Administrator")}
                        </label>
                      </div>
                      <div className="flex gap-3 items-center">
                        <input
                          onChange={handleAccountChange}
                          defaultChecked={accountType === "creator" && true}
                          className="h-5 w-5 cursor-pointer"
                          type="radio"
                          name="account_type"
                          value="creator"
                          id="creator"
                        />
                        <label
                          htmlFor="creator"
                          className="theme-text-24 cursor-pointer"
                        >
                          {t("common.creator", "Creator")}
                        </label>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="flex flex-col gap-1 custom-container-393-XXX">
              {sidebarMenus.map((menu) => {
                return (
                  <div
                    key={menu.id}
                    onClick={() =>
                      menu.id === 8 && setShowSubMenu(!showSubMenu)
                    }
                  >
                    {menu?.subMenus.length &&
                    (menu.id === 8 ? showSubMenu === true : true) ? (
                      <>
                        {menu?.path ? (
                          <Link
                            to={menu?.path}
                            className="theme-text-28 block font-semibold px-16 py-7"
                          >
                            {menu.title}
                          </Link>
                        ) : (
                          <p className="theme-text-28 block font-semibold px-16 py-7">
                            {menu.title}
                          </p>
                        )}
                        <div className="flex flex-col pl-16 pr-8 mx-3 gap-1">
                          {menu.subMenus.map((subMenu) => {
                            let test = false;
                            if (
                              subMenu?.activeTab
                                .toLocaleLowerCase()
                                .includes(
                                  activeTabs?.subMenu.toLocaleLowerCase()
                                ) &&
                              activeTabs?.subMenu !== ""
                            ) {
                              test = true;
                            }
                            return (
                              <Link
                                key={subMenu.id}
                                className={`theme-text-18 block font-semibold text-gray-500 pl-5 py-5 rounded-md ${
                                  test && "bg-gray-200"
                                }`}
                                to={subMenu.path + "?" + SearchQuery}
                              >
                                {subMenu.title}
                              </Link>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <Link
                        className={`theme-text-28 block font-semibold px-16 py-6 hover:bg-gray-200 ${
                          menu?.activeTab
                            .toLocaleLowerCase()
                            .includes(
                              activeTabs?.mainMenu.toLocaleLowerCase()
                            ) && "bg-gray-200"
                        }`}
                        to={menu.path}
                      >
                        {menu.title}
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          </aside>
          <div
            className={`${
              showSidebar ? "col-span-8" : "col-span-11"
            } h-screen flex flex-col overflow-hidden`}
          >
            <div className="sticky top-0">
              <Navbar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
            </div>
            <div
              ref={dashboardLayoutTopRef}
              className={`sticky top-0 grow p-5 w-full ${
                isVhNeeded ? "overflow-hidden" : "overflow-auto"
              }`}
            >
              <div
                className={`border border-gray-500 bg-white ${
                  isVhNeeded && "h-full overflow-auto"
                }`}
                id="dashboardLayout"
              >
                <SidebarMenusContext.Provider
                  value={[sidebarMenus, setSidebarMenus]}
                >
                  <OutletHeightContext.Provider
                    value={[isVhNeeded, setIsVhNeeded]}
                  >
                    <ActiveTabContext.Provider
                      value={[activeTabs, setActiveTabs]}
                    >
                      <Outlet />
                    </ActiveTabContext.Provider>
                  </OutletHeightContext.Provider>
                </SidebarMenusContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
