import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      headers.set(
        "authorization",
        `Bearer ${
          getState().authToken.token || localStorage.getItem("access_token")
        }`
      );
      return headers;
    },
  }),
  tagTypes: ["tour", "stop", "stop-custom-map", "site-setting"],
  endpoints: (builder) => ({}),
});
