import { apiSlice } from "../api/apiSlice";

export const tourSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTour: builder.query({
      query: (id) => `/get-tour/${id}`,
      providesTags: ["tour"],
    }),
    getNewDraftTours: builder.query({
      query: (count) => `/tours/new-draft?page=${count}`,
      providesTags: (result, error, page) =>
        result
          ? [
            ...result.data.data.map(({ id }) => ({ type: "NewDraft", id })),
            { type: "NewDraft", id: "PARTIAL-LIST" },
          ]
          : [{ type: "NewDraft", id: "PARTIAL-LIST" }],
    }),
    getActualizationTours: builder.query({
      query: (count) => `/tours/actualization?page=${count}`,
      providesTags: ["tour"],
    }),
    addNewTour: builder.mutation({
      query: (tour) => ({
        url: "/create-tour",
        method: "POST",
        // Include the entire post object as the body of the request
        body: tour,
      }),
      // invalidatesTags: ['tour']
      invalidatesTags: (result, error, id) => [
        "tour",
        // { type: 'user_tours', id },
        // { type: 'user_tours', id: 'PARTIAL-LIST' },
        { type: "NewDraft", id },
        { type: "NewDraft", id: "PARTIAL-LIST" },
      ],
    }),
    addTourFiles: builder.mutation({
      query: (tour) => ({
        url: "/create-tour",
        method: "POST",
        // Include the entire post object as the body of the request
        body: tour,
      }),
    }),
    deleteTourCreditImages: builder.mutation({
      query: (tour) => ({
        url: "/delete-tour-credit-images",
        method: "POST",
        // Include the entire post object as the body of the request
        body: tour,
      }),
    }),
    validateTourDataFilled: builder.mutation({
      query: (id) => ({
        url: `/validate-tour-data-filled/${id}`,
        method: "GET",
      }),
    }),
    getTourByUserId: builder.query({
      query: () => `/get-tours-by-user-id`,
      providesTags: ["tour"],
      // providesTags: (result, error, id) =>
      // result
      // ? [
      //     ...result.data.map(({ id }) => ({ type: 'user_tours', id })),
      //   { type: 'user_tours', id: 'PARTIAL-LIST' },
      // ]
      // :[{ type: 'user_tours', id: 'PARTIAL-LIST' }],
    }),
    getTourByUserIdParam: builder.query({
      query: (id) => `/get-tours-by-user-id?user_id=${id}`,
      providesTags: ["tour"],
    }),
    getTourByUserIdAndPublishedParam: builder.query({
      query: (id) => `/get-tours-by-user-id?user_id=${id}&is_admin_publish=1`,
      providesTags: ["tour"],
    }),
    getToursReviewsByUserId: builder.query({
      query: () => `/get-tours-reviews-user-id`,
      providesTags: ["tour"],
    }),
    getTourViews: builder.query({
      query: (tourId) => `/get-tour-views?tour_id=${tourId}`,
      providesTags: ["tour_views"],
    }),
    deleteTourById: builder.mutation({
      query: (tourId) => ({
        url: `/delete-tour/${tourId}`,
      }),
      invalidatesTags: ["tour"],
    }),
    deleteTourLangById: builder.mutation({
      query: (data) => ({
        url: `/tour-lang-remove`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ['tour']
      invalidatesTags: (result, error, id) => [
        "tour",
        { type: "NewDraft", id },
        { type: "NewDraft", id: "PARTIAL-LIST" },
      ],
    }),
    getTourStats: builder.mutation({
      query: (data) => ({
        url: `/get-tour-stats?from=${data?.from}&to=${data?.to}`,
        method: "GET",
      }),
    }),

    /** tour map image */
    getTourAllMapImages: builder.query({
      query: (tourId) => `/get-tour-all-map-images/${tourId}`,
      providesTags: ["tourAllMapImages"],
    }),
    addTourMapImage: builder.mutation({
      query: (data) => ({
        url: `/create-tour-map-image`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tourAllMapImages"],
    }),
    updateTourImage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/tour-map-images/edit/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tourAllMapImages"],
    }),
    updateTourTitle: builder.mutation({
      query: ({ id, data }) => ({
        url: `/tour-map-title/edit/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tourAllMapImages"],
    }),
    deleteTourMapImage: builder.mutation({
      query: (id) => ({
        url: `/tour-map-image/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tourAllMapImages", "stop"],
    }),

    /** super admin only */
    adminGetAllTours: builder.query({
      query: () => `/admin/get-all-tours`,
    }),
    adminGetToursByCountryNameCityName: builder.query({
      query: ({ countryName, cityName }) =>
        `/admin/get-tours/${countryName}/${cityName}`,
      providesTags: ["tour"],
    }),
    //frontend website
    userGetCityWithTour: builder.query({
      query: () => `/getCitiesWithTour`,
    }),
    userTourAgainstCity: builder.query({
      query: (cityId) => `/getTourByCityId/${cityId}`,
    }),
  }),
});

export const {
  useGetTourQuery,
  useAddNewTourMutation,
  useValidateTourDataFilledMutation,
  useAdminGetAllToursQuery,
  useAdminGetToursByCountryNameCityNameQuery,
  useGetTourByUserIdQuery,
  useGetTourByUserIdParamQuery,
  useGetTourByUserIdAndPublishedParamQuery,
  useGetToursReviewsByUserIdQuery,
  useGetTourViewsQuery,
  useDeleteTourByIdMutation,
  useDeleteTourLangByIdMutation,
  useGetTourStatsMutation,
  useAddTourFilesMutation,
  useDeleteTourCreditImagesMutation,
  useGetNewDraftToursQuery,
  useGetActualizationToursQuery,
  useAddTourMapImageMutation,
  useGetTourAllMapImagesQuery,
  useUpdateTourImageMutation,
  useUpdateTourTitleMutation,
  useDeleteTourMapImageMutation,
  useUserGetCityWithTourQuery,
  useUserTourAgainstCityQuery
} = tourSlice;
