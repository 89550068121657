import { apiSlice } from "../api/apiSlice";

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => `/getuserInfo`,
      providesTags: ["userInfo"],
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `/login`,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `/signOut`,
        method: "POST",
      }),
      invalidatesTags: ["userInfo"],
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `/register`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["userInfo"],
    }),
    getUsersByCity: builder.query({
      query: ({ cityId, pageNo }) => `/get-users/${cityId}?page=${pageNo}`,
    }),
    acceptDenyUser: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/accept-deny-user/${userId}`,
        method: "POST",
        body: data,
      }),
    }),
    uploadContract: builder.mutation({
      query: (data) => ({
        url: `/upload-contract`,
        method: "POST",
        body: data,
      }),
    }),
    getUserDetailsById: builder.query({
      query: (userId) => `/get-user-details/${userId}`,
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useGetUsersByCityQuery,
  useAcceptDenyUserMutation,
  useUploadContractMutation,
  useGetUserDetailsByIdQuery,
} = authSlice;
