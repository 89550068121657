import { apiSlice } from "../api/apiSlice";

export const LanguageSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLanguage: builder.query({
      query: () => "/language",
    }),
    getLanguageDetail: builder.query({
      query: (id) => `language/${id}`,
      providesTags: ["language"],
    }),
    createLanguage: builder.mutation({
      query: (data) => ({
        url: "/language",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["language"],
    }),
    deleteLanguage: builder.mutation({
      query: (id) => ({
        url: `/language/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["language"],
    }),    
    updateLanguage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/language/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["language"],
    }),
  }),
});

export const {
  useCreateLanguageMutation,
  useDeleteLanguageMutation,
  useGetLanguageQuery,
  useUpdateLanguageMutation,
  useGetLanguageDetailQuery,

} = LanguageSlice;
