import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    status: false,
}

export const stopListDragging = createSlice({
    name: 'stopListDragging',
    initialState,
    reducers: {
        setDragging: (state, action)=>{
            state.status = action.payload
        }
    }
})

export const { setDragging } = stopListDragging.actions

export default stopListDragging.reducer