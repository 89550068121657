import React from "react";

const Shimmer1 = () => {
  return (
    <div className="grid grid-cols-12 gap-3 h-full w-full p-5">
      <div className="col-span-3 h-full shine animate-pulse bg-zinc-100"></div>

      <div className="col-span-9 h-full grid gap-3">
        <div className="animate-pulse bg-zinc-100"></div>
        <div className="animate-pulse bg-zinc-100"></div>
        <div className="animate-pulse bg-zinc-100"></div>
        <div className="animate-pulse bg-zinc-100"></div>
        <div className="animate-pulse bg-zinc-100"></div>
      </div>
    </div>
  );
};

export default Shimmer1;
