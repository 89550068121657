import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import authTokenReducer from "../features/authToken/authToken";
import stopListDragging from "../features/stopListDragging/stopListDragging";

const store = configureStore({
    reducer:{
        [apiSlice.reducerPath]: apiSlice.reducer,
        authToken: authTokenReducer,
        stopListDragging: stopListDragging,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware)
})

export default store