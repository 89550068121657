import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  OutletHeightContext,
  SidebarMenusContext,
} from "../layout/dashboardLayout";
import { useTranslation } from "react-i18next";

const SuAdminMainMenusUtils = ({ isVhNeeded, children }) => {
  const location = useLocation();
  const setIsVhNeeded = useContext(OutletHeightContext)[1];
  const setSidebarMenus = useContext(SidebarMenusContext)[1];
  const { t } = useTranslation();

  let count = 1;
  const sidebarMenusData = [
    {
      id: count++,
      title: t("common.dashboard", "Dashboard"),
      path: "/admin/dashboard",
      activeTab: "dashboard",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.tour", "Tour"),
      path: "/admin/dashboard/tour",
      activeTab: "tour",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.new_draft", "New/Draft"),
      path: "/admin/dashboard/new-draft",
      activeTab: "new/draft",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.actualization", "Actualization"),
      path: "/admin/dashboard/actualization",
      activeTab: "actualization",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.published", "Published"),
      path: "/admin/dashboard/published",
      activeTab: "published",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.users", "Users"),
      path: "/admin/dashboard/users/countries",
      activeTab: "users",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.language", "Languages"),
      path: "/admin/dashboard/language/lists",
      activeTab: "language",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.city", "City"),
      path: "/admin/dashboard/add-city-img",
      activeTab: "add_city_image",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.free_walk", "Free Walk"),
      path: "/admin/dashboard/free-walk/countries",
      activeTab: "free walk",
      subMenus: [
        {
          id: count++,
          title: t("common.manage", "Manage"),
          path: "/admin/dashboard/free-walk/lists",
          activeTab: "list",
        },
        {
          id: count++,
          title: t("common.list", "List"),
          path: "/admin/dashboard/free-walk/countries",
          activeTab: "free-list",
        },
      ],
    },
    {
      id: count++,
      title: t("common.site_setting", "Site Setting"),
      path: "/admin/dashboard/site-setting",
      activeTab: "Site Setting",
      subMenus: [],
    },
    {
      id: count++,
      title: t("common.promotion", "Promotion"),
      path: "/admin/dashboard/promotion",
      activeTab: "promotion",
      subMenus: [],
    },
  ];

  // window.addEventListener("load",()=> {
  //     setSidebarMenus(sidebarMenusData)
  //     setIsVhNeeded(isVhNeeded)
  // })
  useEffect(() => {
    let setPageData = true;
    if (setPageData) {
      setSidebarMenus(sidebarMenusData);
      setIsVhNeeded(isVhNeeded);
    }

    return () => {
      setPageData = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{children}</>;
};

export default SuAdminMainMenusUtils;
