import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { authSlice } from "../../features/auth/authSlice";

const IsSuperAdmin = () => {
  const { data: userData } = authSlice.endpoints.getUserInfo.useQueryState(1);

  if (userData?.data) {
    return userData?.data?.is_admin === 1 ? <Outlet /> : <Navigate to="/" />;
  } else {
    return (
      <div className="h-full grid place-content-center bg-white">
        <p className="text-2xl font-bold">Loading...</p>
      </div>
    );
  }
  // return <Outlet />
};

export default IsSuperAdmin;
