import { apiSlice } from "../api/apiSlice";

export const countryCitySlice = apiSlice.injectEndpoints({
    endpoints: builder=>({
        getAllCountries: builder.query({
            query: ()=>'/get-all-countries'
        }),
        getCountries: builder.mutation({
            query: ()=>({
                url: '/get-all-countries',
                method: 'GET'
            })
        }),
        getAllCountriesWithUsers: builder.query({
            query: ()=>`/get-all-countries-with-users`,
            providesTags: ['countriesWithUser']
        }),
        getAllCitiesWithUsers: builder.query({
            query: (countryCode)=>`/get-all-cities-with-users/${countryCode}`,
            providesTags: ['citiesWithUser']
        }),
        getAllCities: builder.query({
            query: ()=> '/get-all-cities'
        }),
        getCitiesByCountryCode: builder.query({
            query: (countryName)=>`/get-cities/${countryName}`
        }),
        getCitiesByCountry: builder.mutation({
            query: (countryName)=>({
                url: `/get-cities/${countryName}`,
                method: 'GET'
            })
        }),
        getCityOrCountry: builder.mutation({
            query: ({city, country})=>({
                url: `/get-city-or-country?city=${city}&country=${country}`,
                method: 'GET'
            })
        }),
        addCityImage: builder.mutation({
            query: (data)=>({
                url: `/add-city-image`,
                method: 'POST',
                body: data
            })
        })
    })
})

export const {
    useGetAllCountriesQuery,
    useGetCountriesMutation,
    useGetAllCountriesWithUsersQuery, 
    useGetAllCitiesWithUsersQuery,
    useGetAllCitiesQuery, 
    useGetCitiesByCountryCodeQuery, 
    useGetCitiesByCountryMutation,
    useGetCityOrCountryMutation,
    useAddCityImageMutation
} = countryCitySlice