import React, { useEffect, useRef, useState } from 'react';
import { useUserGetCityWithTourQuery } from '../../features/tour/TourSlice';
import 'leaflet/dist/leaflet.css'; // Импортируем CSS для Leaflet
import L from 'leaflet'; // Импортируем библиотеку Leaflet
import markerIcon from '../../assets/img/icon/geometka.png'

const TourTable = () => {
  const { data: citiesWithTour, refetch: refetchTour } = useUserGetCityWithTourQuery();
  const [countryTourData, setCountryTourData] = useState({ countries: 0, tours: 0 });
  const [aggregatedData, setAggregatedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await refetchTour();  // Refetch the data
      if (citiesWithTour && citiesWithTour.data) {
        const citiesData = citiesWithTour.data;
        const countryMap = new Map();

        // Aggregate data by country
        citiesData.forEach(city => {
          if (!countryMap.has(city.country)) {
            countryMap.set(city.country, { cityCount: 0, tourCount: 0 });
          }
          const countryData = countryMap.get(city.country);
          countryData.cityCount += 1;
          countryData.tourCount += city.tour_count;
        });

        const aggregatedCountries = Array.from(countryMap, ([country, data]) => ({
          country,
          cityCount: data.cityCount,
          tourCount: data.tourCount
        }));

        setCountryTourData({
          countries: countryMap.size,
          tours: aggregatedCountries.reduce((total, item) => total + item.tourCount, 0)
        });

        setAggregatedData(aggregatedCountries);
      }
    };
    fetchData();
  }, [refetchTour, citiesWithTour]);
  function SimpleMap({ citiesReducer }) {

    // Состояние для хранения массива городов
    const [loadedCities, setLoadedCities] = React.useState([]);
    const mapContainerRef = useRef(null); // Создаем ref для контейнера карты
    const mapRef = useRef(null); // Создаем ref для объекта карты
    // Загрузка данных городов из редуктора в состояние
    useEffect(() => {
      setLoadedCities(citiesReducer);
    }, [citiesReducer]);
    // Состояние для хранения массива городов с координатами
    const [citiesWithCoordinates, setCitiesWithCoordinates] = useState([]);
    // useEffect для загрузки координат городов
    useEffect(() => {
      const fetchData = async () => {
        if (loadedCities.length > 0) {
          const updatedCities = await Promise.all(loadedCities.map(async city => {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${city.name}&format=json`);
            const data = await response.json();
            if (data.length > 0) {
              return {
                ...city,
                coordinates: [parseFloat(data[0].lat), parseFloat(data[0].lon)]
              };
            } else {
              return city;
            }
          }));
          setCitiesWithCoordinates(updatedCities);
        }
      };
      fetchData();
    }, [loadedCities]);
    // Инициализация и обновление карты
    useEffect(() => {
      if (!mapContainerRef.current) return;
      if (!mapRef.current) {
        // const map = L.map(mapContainerRef.current).setView(citiesWithCoordinates[0].coordinates, 3);
        const map = L.map(mapContainerRef.current).setView([25, 0], 2); // Установка центра карты близко к северному полюсу
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);
        mapRef.current = map;
      }
      const map = mapRef.current;
      const customIcon = L.icon({
        iconUrl: markerIcon,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
      });
      map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });
      citiesWithCoordinates.forEach(city => {
        if (city.coordinates) {
          L.marker(city.coordinates, { icon: customIcon }).addTo(map).bindPopup(city.name);
        }
      });
      return () => {
        map.eachLayer(layer => {
          if (layer instanceof L.Marker) {
            map.removeLayer(layer);
          }
        });
      };
    }, [citiesWithCoordinates]);
    return (
      <div ref={mapContainerRef} id="map" style={{ height: "400px", width: "100%" }}></div>
    );
  }
  return (
    <div className="table-container">
      <h1 className='font-52'>World tour audio guide countries</h1>
      <SimpleMap citiesReducer={citiesWithTour?.data} />
      <h2 className='font-52 mt-3'>World tour audio guide countries</h2>
      <div className='tablecontent'>
        <div>
          <h4>{countryTourData.countries} countries</h4>
        </div>
        <div>
          <h4>{countryTourData.tours} tours</h4>
        </div>
      </div>
      <table className="tour-table">
        <thead>
          <tr>
            <th>Country</th>
            <th>Cities</th>
            <th>Tours</th>
          </tr>
        </thead>
        <tbody>
          {aggregatedData.map((data, index) => (
            <tr key={index}>
              <td>{data.country}</td>
              <td>{data.cityCount}</td>
              <td>{data.tourCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TourTable;
