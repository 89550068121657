import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import {
  useAddCityImageMutation,
  useGetCitiesByCountryMutation,
  useGetCityOrCountryMutation,
  useGetCountriesMutation,
} from "../../features/countryCity/countryCitySlice";
import SetActiveTab from "../../utils/SetActiveTab";
import SuAdminMainMenusUtils from "../../utils/SuAdminMainMenusUtils";
import fileIcon from "../../assets/images/file-icon-white.svg";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const SuAdminDashboardAddCityImage = () => {
  SetActiveTab("add_city_image");

  const { t } = useTranslation();
  const [countries, setCountries] = useState();
  const [cities, setCities] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const { pathname } = useLocation();
  const cityImageFileInputRef = useRef();

  const [getAllCountries] = useGetCountriesMutation();
  const [getCitiesByCountry] = useGetCitiesByCountryMutation();
  const [
    getCityOrCountry,
    { data: selectedCityData, isLoading: cityOrCountryLoading },
  ] = useGetCityOrCountryMutation();
  const [addCityImage] = useAddCityImageMutation();

  /** --------useEffects-------- */
  useEffect(() => {
    getAllCountries()
      .then((res) => {
        const { data: allCountries } = res;
        const countriesForSelect = allCountries?.data?.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(countriesForSelect);
      })
      .catch((err) => console.log(err));
  }, [pathname, getAllCountries, getCityOrCountry]);

  useEffect(() => {
    if (selectedCountry) {
      setSelectedCity([]);
      setCities(null);
      getCitiesByCountry(selectedCountry).then((res) => {
        const { data: citiesByCountry } = res;
        const citiesForSelect = citiesByCountry?.data?.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCities(citiesForSelect);

        /**---to clear the existing image if new country got selected---- */
        getCityOrCountry().unwrap();
      });
    }
  }, [selectedCountry, getCitiesByCountry, pathname]);

  useEffect(() => {
    if (selectedCity) {
      getCityOrCountry({ city: selectedCity }).unwrap();
    }
  }, [selectedCity, getCityOrCountry]);

  /**-------Event Handlers-------- */
  const handleSelectInputChange = (value, selectName) => {
    if (selectName === "country") {
      setSelectedCountry(value);
    }
    if (selectName === "city") {
      setSelectedCity(value);
    }
  };

  const handleFileInputClick = (fileInput) => {
    fileInput?.current?.click();
  };

  const handleInputFileChange = (e) => {
    const cityImgFile = e.target.files[0];
    const newForm = new FormData();
    newForm.append("city-id", selectedCity);
    newForm.append("city-image", cityImgFile);
    addCityImage(newForm).then((res) => {
      if (res?.data?.city_image) {
        getCityOrCountry({ city: selectedCity }).unwrap();
      }
    });
  };

  return (
    <SuAdminMainMenusUtils isVhNeeded={true}>
      <section>
        <div className="sticky top-0 z-10 bg-white">
          <div className="p-5 border-b ">
            <h3 className="theme-text-36 font-semibold my-2">
              {t("common.city", "City")}
            </h3>
          </div>
          <div className="grid grid-cols-12 gap-8 px-5 pt-5 pb-2">
            <div className="col-span-4 relative">
              {selectedCountry ? (
                <Select
                  value={countries?.filter(
                    (item) => item.value === parseInt(selectedCountry)
                  )}
                  onChange={({ value }) =>
                    handleSelectInputChange(value, "country")
                  }
                  styles={{
                    control: () => ({
                      display: "flex",
                      padding: "8px 0px",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }),
                  }}
                  options={countries}
                  placeholder={t("common.select_country", "Select a country")}
                />
              ) : (
                <Select
                  onChange={({ value }) =>
                    handleSelectInputChange(value, "country")
                  }
                  styles={{
                    control: () => ({
                      display: "flex",
                      padding: "8px 0px",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }),
                  }}
                  options={countries}
                  placeholder={t("common.select_country", "Select a country")}
                />
              )}
              <input
                defaultValue={selectedCountry}
                name="country"
                className="absolute bottom-0 left-[50%] opacity-0 bg-transparent text-[1px]"
                type="text"
                required
              />
            </div>
            <div className="col-span-4 relative">
              {cities?.length !== 0 ? (
                <Select
                  onChange={({ value }) =>
                    handleSelectInputChange(value, "city")
                  }
                  // {...formData?.city ? {value:[{value: formData?.city, label: formData?.city}]} : {placeholder: 'Select a city'}}
                  value={cities?.filter(
                    (city) => city.value === parseInt(selectedCity)
                  )}
                  styles={{
                    control: () => ({
                      display: "flex",
                      padding: "8px 0px",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }),
                  }}
                  options={cities}
                />
              ) : (
                <Select
                  onChange={({ value }) =>
                    handleSelectInputChange(value, "city")
                  }
                  styles={{
                    control: () => ({
                      display: "flex",
                      padding: "8px 0px",
                      border: "1px solid gray",
                      borderRadius: "5px",
                    }),
                  }}
                  {...(selectedCity
                    ? {
                        value: cities.filter(
                          (city) => city.value === parseInt(selectedCity)
                        ),
                      }
                    : {
                        placeholder: t("common.select_city", "Select a city"),
                      })}
                />
              )}
              <input
                defaultValue={selectedCity}
                name="city"
                className="absolute bottom-0 left-[50%] opacity-0 bg-transparent text-[1px]"
                type="text"
                required
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-12 p-5">
          {selectedCityData?.data?.city ? (
            <div className="flex flex-col gap-5">
              {selectedCityData?.data?.city?.image ? (
                <div className="w-full lg:w-[80%] xl:w-[70%] lg:mx-auto">
                  <img
                    className="w-full rounded-xl"
                    src={selectedCityData?.data?.city?.image}
                    alt=""
                  />
                </div>
              ) : (
                <div className="bg-gray-200 rounded-md text-center px-5 flex justify-center py-28">
                  <p className="text-[18px] font-bold text-zinc-500">
                    {t(
                      "common.no_image_available",
                      "No image available for the city"
                    )}
                  </p>
                </div>
              )}
              <div className="flex justify-center">
                <div
                  onClick={() => handleFileInputClick(cityImageFileInputRef)}
                  className="bg-theme-dark flex flex-wrap justify-center items-center py-4 px-12 rounded-full gap-3 cursor-pointer"
                >
                  <img className="w-3" src={fileIcon} alt="" />
                  <span className="text-16 text-white">
                    {t("common.upload_picture", "Upload picture")}
                  </span>
                </div>
                <input
                  onChange={handleInputFileChange}
                  ref={cityImageFileInputRef}
                  type="file"
                  name="cover_image"
                  className="hidden"
                  accept="image/png, image/jpg, image/jpeg"
                />
              </div>
            </div>
          ) : (
            <div className="bg-gray-200 rounded-md text-center px-5 flex justify-center py-28">
              <p className="text-[20px] font-bold text-zinc-500">
                {t(
                  "common.city_country_image",
                  "Please select Country & City to upload an image"
                )}
              </p>
            </div>
          )}
        </div>

        {cityOrCountryLoading && (
          <section className="fixed left-0 top-0 w-full h-screen py-5 overflow-hidden bg-black/[0.7] flex justify-center items-center z-[999]">
            <div className="min-w-[40%] max-w-[80%] xl:max-w-[60%] max-h-[100%] overflow-auto bg-white mx-auto rounded-md">
              <section className="flex justify-center py-20">
                <svg
                  className="animate-spin h-12 w-12 text-blue-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </section>
            </div>
          </section>
        )}
      </section>
    </SuAdminMainMenusUtils>
  );
};

export default SuAdminDashboardAddCityImage;
